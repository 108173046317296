import React, { Component } from 'react'
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Download from '../Download'
import BankInvites from '../../api/bank.invites'
import { ToastContainer, toast } from 'react-toastify'
import Modal from 'react-modal'

export default class BankInvitesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invites: null,
      page: 1,
      totalCount: 10,
      searchText: '',
      invite_id: 0,
      modal_status: 0,
    }
    this.getBankInvites = this.getBankInvites.bind(this)
    this.returnInviteStatus = this.returnInviteStatus.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.setCurrentInvite = this.setCurrentInvite.bind(this)
    this.updateInviteStatus = this.updateInviteStatus.bind(this)
  }

  async componentDidMount() {
    await this.getBankInvites()
  }

  returnInviteStatus(status) {
    switch (status) {
      case 1:
        return 'Во исчекување'
      case 2:
        return 'Прифатена'
      case 3:
        return 'Одбиена'
    }
  }
  setCurrentInvite(inviteId, status) {
    this.setState({
      invite_id: inviteId,
      modal_status: status,
      modalIsOpen: true,
    })
  }
  async handlePageClick(e) {
    await this.getBankInvites(e.selected + 1)
  }
  async getBankInvites(pageNumber) {
    try {
      const result = await BankInvites.getAll(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText,
      )
      this.setState({ invites: result.invites })
    } catch (error) {
      toast.error('Грешка при преземањето на клиентите')
    }
  }
  async updateInviteStatus() {
    try {
      await BankInvites.updateInviteStatus(
        this.state.invite_id,
        this.state.modal_status,
      )
      await this.getBankInvites()
      this.closeModal()
    } catch (error) {
      console.log(error)
      toast.error('Настана грешка')
    }
  }
  setIsOpen(value) {
    this.setState({ modalIsOpen: value })
  }
  openModal() {
    this.setIsOpen(true)
  }
  closeModal() {
    this.setIsOpen(false)
    this.setState({ invite_id: 0, model_status: 0 })
  }

  render() {
    Modal.setAppElement('#root')
    const customStyles = {
      content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    }
    return (
      <Row className="table-section">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <Form>
            <Form.Group as={Row}>
              <Form.Label className="black">
                {this.state.modal_status == 2
                  ? `Дали сте сигурни дека сакате да потврдите препораката?`
                  : `Дали сте сигурни дека сакате да одбиете препораката?`}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Button
                onClick={this.updateInviteStatus}
                className="mr-2 btn btn-success"
              >
                Да
              </Button>
              <Button onClick={this.closeModal} className="btn btn-danger">
                Откажи
              </Button>
            </Form.Group>
          </Form>
        </Modal>
        <Col lg={12}>
          <figure className="icon-group">
            {/* <img src={OneTimeIcon} alt='One-Time Check Icon' /> */}
            <h2 className="heading ml-3">Прифатени препораки</h2>
          </figure>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Клиент</th>
                  <th>Шпаркасе сметка</th>
                  <th>Код за препорака</th>
                  <th>Креирана на</th>
                  <th>Статус</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.invites
                  ? this.state.invites.rows.map((i) => {
                      let date = new Date(i.created).toLocaleString('en-GB')
                      return (
                        <tr key={i._id}>
                          <td>
                            {i.first_name} {i.last_name}
                          </td>
                          <td>{i.bank_account}</td>
                          <td>{i.code}</td>
                          <td>{date}</td>
                          <td>{this.returnInviteStatus(i.status)}</td>
                          <td>
                            {i.status == 1 ? (
                              <div>
                                <Button
                                  onClick={() =>
                                    this.setCurrentInvite(i._id, 2)
                                  }
                                  className="btn btn-success"
                                >
                                  Потврди
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.setCurrentInvite(i._id, 3)
                                  }
                                  className="btn btn-danger ml-4"
                                >
                                  Одбиј
                                </Button>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Претходна'}
              nextLabel={'Следна >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.invites && this.state.invites.count
                  ? Math.ceil(this.state.invites.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    )
  }
}
