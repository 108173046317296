import React, { Component } from 'react'
import { Row, Col, Form, Table } from 'react-bootstrap'
import Cookies from 'js-cookie'
import reportsApi from '../../api/reports-api'
import DownloadBotStatistics from './DownloadBotStatistics'

export default class ViberBotStatistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statistics: [],
      isSuperAdmin: JSON.parse(Cookies.get('isSuperAdmin')),
    }
  }

  async getStatistics() {
    try {
      const result = await reportsApi.getViberStatistics()
      console.log(result)
      this.setState({
        statistics: result,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    await this.getStatistics()
  }

  render() {
    return (
      <Row className="table-section">
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">Вибер бот статистика</h2>
          </figure>
          <Form inline onSubmit={this.handleSubmit}>
            <DownloadBotStatistics />
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Акција</th>
                  <th>Посети</th>
                </tr>
              </thead>
              <tbody>
                {this.state.statistics
                  ? this.state.statistics.map((i) => {
                      return (
                        <tr key={i._id}>
                          <td>{i.action}</td>
                          <td>{i.visits}</td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </section>
        </Col>
      </Row>
    )
  }
}
