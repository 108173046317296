import React, { Component } from 'react';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import MeetingsAPI from '../api/meetings.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import spinner from '../images/spinner.gif';
import DatePicker from 'react-datepicker';
import ExportMeetings from '../components/ExportMeetings';
import ExportMeetingsAll from '../components/ExportMeetingsAll'
import Cookies from 'js-cookie';

export default class Meetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetings: null,
      page: 1,
      totalCount: 10,
      searchText: '',
      currentMeetingId: 0,
      modalIsOpen: false,
      isLoading: false,
      newMeetingModalIsOpen: false,
      date: new Date(),
      role: Cookies.get('role')
    };

    this.getMeetings = this.getMeetings.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setCurrentMeeting = this.setCurrentMeeting.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.approveMeeting = this.approveMeeting.bind(this);
    this.proposeNewTime = this.proposeNewTime.bind(this);
  }
  onChange = (date) => this.setState({ date });

  async getMeetings(page = 0) {
    try {
      const meetingsList = await MeetingsAPI.getAll(
        page === 0 ? this.state.page : page,
        10,
        this.state.searchText
      );
      this.setState({ meetings: meetingsList.meetings });
    } catch (error) {
      console.log(error);
      toast.error('Грешка при читање на состаноците');
    }
  }
  async componentDidMount() {
    await this.getMeetings();
    this.interval = setInterval(() => {
      this.getMeetings();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async handlePageClick(e) {
    await this.getMeetings(e.selected + 1);
  }

  setCurrentMeeting(meetingId, newMeetingModal) {
    if (!newMeetingModal)
      this.setState({ currentMeetingId: meetingId, modalIsOpen: true });
    else {
      this.setState({
        currentMeetingId: meetingId,
        newMeetingModalIsOpen: true,
      });
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false, newMeetingModalIsOpen: false });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async approveMeeting() {
    try {
      this.setState({ isLoading: true });
      const result = await MeetingsAPI.approveMeeting(
        this.state.currentMeetingId
      );
      if (result.status === 200) {
        toast.success('Состанокот е успешно закажан');
        this.setState({
          currentMeetingId: 0,
          modalIsOpen: false,
          isLoading: false,
        });
        await this.getMeetings();
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      toast.error('Грешка при потврдувањето на терминот');
    }
  }
  async proposeNewTime() {
    try {
      this.setState({ isLoading: true });
      const result = await MeetingsAPI.proposeNewMeeting(
        this.state.currentMeetingId,
        this.state.date
      );
      if (result.status === 200) {
        toast.success('Терминот е успешно предложен');
        this.setState({
          currentMeetingId: 0,
          newMeetingModalIsOpen: false,
          isLoading: false,
        });
        await this.getMeetings();
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      toast.error('Грешка при предложувањето на терминот');
    }
  }
  render() {
    Modal.setAppElement('#root');

    const customStylesNewTermin = {
      content: {
        top: window.innerWidth <= 760 ? '60%' : '40%',
        left: window.innerWidth >= 760 ? '50%' : '70%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: this.state.newMeetingModalIsOpen ? '500px' : 'auto',
        height: this.state.newMeetingModalIsOpen ? '370px' : 'auto',
        textAlign: 'center',
      },
    };
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: this.state.newMeetingModalIsOpen ? '500px' : 'auto',
        height: this.state.newMeetingModalIsOpen ? '370px' : 'auto',
        textAlign: 'center',
      },
    };
    return (
      <Row className='table-section'>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel='Example Modal'
          style={customStyles}
        >
          {this.state.isLoading == true ? (
            <img src={spinner}></img>
          ) : (
              <Form>
                <Form.Group as={Row}>
                  <Form.Label className='black'>
                    Дали сте сигурни дека сакате да го пoтврдите овај термин?
                </Form.Label>
                </Form.Group>

                <Form.Group as={Row} controlId='formPlaintextPassword'>
                  <Button
                    onClick={this.approveMeeting}
                    className='mr-2 btn btn-success'
                  >
                    Да
                </Button>
                  <Button onClick={this.closeModal} className='btn btn-danger'>
                    Откажи
                </Button>
                </Form.Group>
              </Form>
            )}
        </Modal>
        <Modal
          isOpen={this.state.newMeetingModalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel='Example Modal'
          style={customStylesNewTermin}
        >
          {this.state.isLoading == true ? (
            <img src={spinner}></img>
          ) : (
              <Form>
                <Form.Group as={Row}>
                  <Form.Label className='black'>Изберете нов термин</Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.onChange}
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={60}
                    dateFormat='Pp'
                    dateFormat='dd/MM/yyyy HH:mm'
                    minDate={new Date()}
                  />
                </Form.Group>
                <Form.Group as={Row} controlId='formPlaintextPassword'>
                  <Button
                    onClick={this.proposeNewTime}
                    className='mr-2 btn btn-success'
                  >
                    Предложи
                </Button>
                  <Button onClick={this.closeModal} className='btn btn-danger'>
                    Откажи
                </Button>
                </Form.Group>
              </Form>
            )}
        </Modal>
        <Col lg={12}>
          <figure className='icon-group'>
            <h2 className='heading ml-3'>Состаноци</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type='number'
              name='searchText'
              placeholder='Пребарај Клиент'
              className='mr-sm-2'
              onChange={this.handleChange}
            />
            <Button
              variant='btn mr-2 button-standard'
              onClick={() => this.getMeetings()}
            >
              <i className='material-icons'>search</i>
              Пребарај
            </Button>
            <Button
              className='btn mr-2 button-standard'
              onClick={() => this.getMeetings()}
            >
              <i className='material-icons'>refresh</i> Освежи
            </Button>
            {
              console.log(this.state.role)
            }
            {
              this.state.role == 1 ? 
              <ExportMeetingsAll /> 
              : <ExportMeetings />
            }

          </Form>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead className='table-head'>
                <tr>
                  <th>Име на Клиент</th>
                  <th>Телефон на Клиент</th>
                  <th>Адреса на Клиент</th>
                  <th>Емаил на Клиент</th>
                  <th>Креиран на</th>
                  <th>Посакуван термин</th>
                  <th>Предложен нов термин</th>
                  <th>Состанок</th>
                  <th>Статус</th>
                  <th>Акции</th>
                </tr>
              </thead>
              <tbody>
                {this.state.meetings
                  ? this.state.meetings.rows.map((i) => {
                    let date = new Date(i.dateTime).toLocaleString('en-GB');
                    let newTime =
                      i.proposedNewTime != null
                        ? new Date(i.proposedNewTime).toLocaleString('en-GB')
                        : 'нема';
                    if (i.user) {
                      return (
                        <tr
                          key={i._id}
                          className={
                            i.status === 'Предложениот термин беше одбиен'
                              ? 'declined-meeting-tr'
                              : ''
                          }
                        >
                          <td>{i.requiredFrom}</td>
                          <td>{i.user.phone}</td>
                          <td>{i.user.address}</td>
                          <td>{i.user.email}</td>
                          <td>{date}</td>
                          <td>
                            {i.meetingDay} , {i.meetingTime}
                          </td>
                          <td>{newTime}{i.previewedFromContact ? '- Контакт центар' : ''}</td>

                          {i.clientType === 'Правно лице' ? (
                            i.region != null ? (
                              <td>
                                {i.contact}- {i.region}- {i.city}-{' '}
                                {i.clientType}-{i.serviceType}
                              </td>
                            ) : (
                                <td>
                                  {i.contact}-{i.serviceType}
                                </td>
                              )
                          ) : (
                              <td>{i.branchOffice}</td>
                            )}

                          <td>{i.status}</td>

                          {i.status === 'Закажан' ? (
                            <td></td>
                          ) : (
                              <td>
                                <Button
                                  onClick={() => this.setCurrentMeeting(i._id)}
                                  className='btn btn-success btn-xs mr-2'
                                >
                                  <i className='material-icons'>done</i>
                                  Потврди
                                </Button>
                                {i.status == 'Во очекување' ? (
                                  <Button
                                    onClick={() =>
                                      this.setCurrentMeeting(i._id, true)
                                    }
                                  >
                                    <i className='material-icons'>create</i>
                                    Измени термин
                                  </Button>
                                ) : null}
                              </td>
                            )}
                        </tr>
                      );
                    }
                  })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Претходна'}
              nextLabel={'Следна >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.meetings && this.state.meetings.count
                  ? Math.ceil(this.state.meetings.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link z-index-0'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
              className='z-index-0'
            />
          </section>
        </Col>
      </Row>
    );
  }
}
