import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap'
import UsersApi from '../../api/users.api'
import messagesApi from '../../api/messages.api'
import { toast } from 'react-toastify'

export default class NumbersFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      numbersFileName: '',
      fileError: null,
      bulkOption: 0,
    }
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.SendBulk = this.SendBulk.bind(this)
  }
  validateForm() {
    let formValid = true
    if (this.state.numbersFileName == '') {
      this.setState({ fileError: 'Ве молиме одберете датотека со броеви' })
      formValid = false
    }
    return formValid
  }
  async SendBulk() {
    if (this.validateForm()) {
      var response = await messagesApi.sendBulk({
        fileName: this.state.numbersFileName,
      })
      this.setState({
        selectedFile: null,
        numbersFileName: '',
        fileError: null,
      })
      toast.success('Пораката беше испратена')
    }
  }

  async onChangeHandler(event) {
    const target = event.target.files[0].name.slice(
      ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
    )
    if (target.toLowerCase() == 'xlsx') {
      const data = new FormData()
      data.append('file', event.target.files[0])
      var response = await UsersApi.uploadNumbers(data)
      console.log(response)
      this.setState({ numbersFileName: response.data.filename, fileError: '' })
    } else {
      this.setState({
        fileError: 'Неправилен формат на фајл. Форматот мора да биде .xlsx',
      })
    }
  }

  render() {
    return (
      <Row>
        <Col>
          <Form>
            <Form.Group as={Row}>
              <Col sm="10">
                <Col>
                  <label className="col-md-12 pl-0 bold-text">
                    Одберeте датотека со броеви
                  </label>
                  <input
                    type="file"
                    name="numbersFileName"
                    onChange={this.onChangeHandler}
                    value={this.state.selectedFile}
                  />
                  <p className="error">{this.state.fileError}</p>
                </Col>
              </Col>
            </Form.Group>
            <Form.Group as={Row}></Form.Group>
          </Form>
        </Col>
      </Row>
    )
  }
}
