import Axios from "axios";
import Cookies from "js-cookie";

class LegalEntitiesApi{
    async getAllLegalEntities(pageNumber, totalCount, searchText) {
        const token = Cookies.get("jwt");
        const configAPi = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
    
        try {
          const r = await Axios.post( 
            process.env.REACT_APP_STAGE === "production"
              ? `${process.env.REACT_APP_API_BASE_URL}/legalEntities/all`
              : `${process.env.REACT_APP_LOCAL_API_URL}/legalEntities/all`,
            { pageNumber, totalCount, searchText },
            configAPi
          );
          return r.data;
        } catch (err) {
          throw err;
        }
      }

      async editLegalEntity(legalEntityId, email, director) {
        try {
          const token = Cookies.get("jwt");
          const configAPi = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          await Axios.post(
            process.env.REACT_APP_STAGE === "production"
              ? `${process.env.REACT_APP_API_BASE_URL}/legalEntities/edit-legalEntity`
              : `${process.env.REACT_APP_LOCAL_API_URL}/legalEntities/edit-legalEntity`,
            { legalEntityId, email, director },
            configAPi
          );
        } catch (error) {
          throw error;
        }
      }
}

export default new LegalEntitiesApi();


