import Axios from 'axios';
import Cookies from 'js-cookie';
class MeetingsAPI {
  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/meetings/getAll`
          : `${process.env.REACT_APP_LOCAL_API_URL}/meetings/getAll`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async approveMeeting(meetingId) {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/meetings/approveMeeting`
          : `${process.env.REACT_APP_LOCAL_API_URL}/meetings/approveMeeting`,
        { meetingId },
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }

  async proposeNewMeeting(meetingId, dateTime) {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/meetings/propose-new-meeting`
          : `${process.env.REACT_APP_LOCAL_API_URL}/meetings/propose-new-meeting`,
        { meetingId, dateTime },
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }

  async getAllForExport() {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/meetings/export`
          : `${process.env.REACT_APP_LOCAL_API_URL}/meetings/export`,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new MeetingsAPI();
