import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LoginPage from "./components/Login";
import {
  Navbar,
  Nav,
  NavDropdown,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import UsersList from "./components/UsersList";
import Messages from "./components/Messages";
import Admins from "./components/Admins";
import Branches from "./components/Branches";
import LegalEntities from "./components/LegalEntities";
import Logo from "./images/logo.png";
import Cookies from "js-cookie";
import { getUserFromJWT } from "./utils/jwt.decode";
import Download from "./components/Download";
import Answers from "./components/Answers";
import { ToastContainer } from "react-toastify";
import Meetings from "./components/Meetings";
import SendBulk from "./components/SendBulk";
import VisaApplications from "./components/VisaApplications";
import SmsBulk from "./components/SmsBulk";
import ViberBotStatistics from "./components/Reports/ViberBotStatistics";
import BulkStatistics from "./components/Reports/BulkStatistics";
import BankReferrals from "./components/BankReferrals/BankReferralsList";
import BankInvitesList from "./components/BankReferrals/BankInvitesList";
import EmailBulk from "./components/EmailBulk";
import CombinedBulk from "./components/Bulk/CombinedBulk";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isLoggedIn: false,
    };
    this.reRender = this.reRender.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  componentDidMount() {
    const user = getUserFromJWT();
    if (user) {
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
          role: user.role,
        },
        isLoggedIn: true,
      });
      Cookies.set("currentUser", user.userName);
      Cookies.set("isSuperAdmin", user.isSuperAdmin);
      Cookies.set("role", user.role);
    }
  }

  handleLogOut() {
    Cookies.remove("jwt");
    Cookies.remove("currentUser");
    Cookies.remove("isSuperAdmin");
    Cookies.remove("role");
    this.setState({ currentUser: null, isLoggedIn: false });
    window.location.replace("/");
  }

  reRender() {
    this.forceUpdate();
    const user = getUserFromJWT();
    if (user)
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
          role: user.role,
        },
        isLoggedIn: true,
      });
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Router>
          <ToastContainer />
          <Navbar
            sticky="top"
            expand="lg"
            variant="light"
            className="main-navigation"
          >
            <Navbar.Brand>
              <Link to="/" className="navbar-brand">
                <img src={Logo} alt="neotel-logo" className="height-70" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav>
                  <Link to="/users" className="nav-link-item">
                    <i className="material-icons">people</i>Клиенти
                  </Link>
                </Nav>
                <Nav>
                  {this.state.currentUser &&
                  (this.state.currentUser.role == 1 ||
                    this.state.currentUser.role == 5) ? (
                    <Link to="/messages" className="nav-link-item">
                      <i className="material-icons">question_answer</i>
                      Коментари/Прашања
                    </Link>
                  ) : null}
                </Nav>
                <Nav>
                  <Link to="/meetings" className="nav-link-item">
                    <i className="material-icons">date_range</i>Состаноци
                  </Link>
                </Nav>
                {this.state.currentUser &&
                (this.state.currentUser.role == 1 ||
                  this.state.currentUser.role == 5) ? (
                  <Nav>
                    <Link to="/send-email-bulk" className="nav-link-item">
                      <i className="material-icons">credit_card</i>
                      Email Булк
                    </Link>
                    <Link to="/send-bulk" className="nav-link-item">
                      <i className="material-icons">contact_mail</i>
                      Viber Булк
                    </Link>
                    {/* <Link to="/send-sms-bulk" className="nav-link-item">
                      <i className="material-icons">contact_mail</i>
                      SMS Булк
                    </Link> */}
                    <Link to="/send-combined-bulk" className="nav-link-item">
                      <i className="material-icons">contact_mail</i>
                      Булк
                    </Link>
                  </Nav>
                ) : null}
                {this.state.currentUser &&
                this.state.currentUser.isSuperAdmin ? (
                  <Nav>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Акции
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/admins">
                          <Link to="/admins">
                            <i className="material-icons">account_box</i>
                            Администратори
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/branches">
                          <Link to="/branches">
                            <i className="material-icons">business</i>
                            Експозитури
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/legalEntities">
                          <Link to="/legalEntities">
                            <i className="material-icons">supervisor_account</i>
                            Правни лица
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/viber-statistics">
                          <Link to="/viber-statistics">
                            <i className="material-icons">filter_list</i>
                            Статистика вибер бот
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bulk-statistics">
                          <Link to="/bulk-statistics">
                            <i className="material-icons">poll</i>
                            Репорти
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bank-referrals">
                          <Link to="/bank-referrals">
                            <i className="material-icons">perm_identity</i>
                            Препорачај ја Шпаркасе
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bank-invites">
                          <Link to="/bank-invites">
                            <i className="material-icons">list</i>
                            Потврди препорака
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav>
                ) : null}
              </Nav>

              <Nav>
                <i className="material-icons md-24 logged-user">
                  account_circle
                </i>
                <NavDropdown
                  title={Cookies.get("currentUser")}
                  id="basic-nav-dropdown"
                  className="nav-link-item"
                >
                  <NavDropdown.Item
                    onClick={this.handleLogOut}
                    className="nav-ling-item"
                  >
                    Одјави се
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route exact path="/">
              <UsersList />
            </Route>
            <Route path="/users">
              <UsersList />
            </Route>
            <Route path="/messages" exact>
              <Messages />
            </Route>
            <Route path="/messages/:id" component={Answers} />
            <Route path="/meetings" exact>
              <Meetings />
            </Route>
            <Route path="/admins">
              <Admins />
            </Route>
            <Route path="/branches">
              <Branches />
            </Route>
            <Route path="/legalEntities">
              <LegalEntities />
            </Route>
            <Route path="/export">
              <Download></Download>
            </Route>
            <Route path="/send-bulk">
              <SendBulk />
            </Route>
            <Route path="/send-sms-bulk">
              <SmsBulk />
            </Route>
            <Route path="/send-email-bulk">
              <EmailBulk />
            </Route>
            {/* <Route path="/visa-applications">
              <VisaApplications></VisaApplications>
            </Route> */}
            <Route
              path="/viber-statistics"
              component={ViberBotStatistics}
            ></Route>
            <Route path="/bulk-statistics" component={BulkStatistics}></Route>
            <Route path="/bank-referrals">
              <BankReferrals />
            </Route>
            <Route path="/bank-invites">
              <BankInvitesList />
            </Route>
            <Route path="/send-combined-bulk">
              <CombinedBulk></CombinedBulk>
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <LoginPage afterLogin={this.reRender} />;
    }
  }
}
export default App;
