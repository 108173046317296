import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import NumbersFile from "./Bulk/NumbersFile";
import messagesApi from "../api/messages.api";
import { toast } from "react-toastify";
import UsersApi from '../api/users.api'

export default class SmsBulk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageText: "",
            remainingCharacters: 160,
            remainingCharErr: null,
            tagLabel: "",
            tagLabelErr: null,
            selectedFile: null,
            numbersFileName: "",
            fileError: null,
            bulkOption: 0,
            inputFileKey: '',
        };
        this.SendBulk = this.SendBulk.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this)
    }
    async SendBulk() {
        if (this.validateForm()) {
            var response = await messagesApi.sendSmsBulk({
                text: this.state.messageText,
                campaignLabel: this.state.tagLabel,
                fileName: this.state.numbersFileName,
            });
            this.setState({
                messageText: "",
                remainingCharacters: 160,
                remainingCharErr: null,
                tagLabel: "",
                selectedFile: null,
                numbersFileName: "",
                fileError: null,
                tagLabelErr: null,
                inputFileKey: "reset",
            });
            toast.success("Пораката беше испратена");

        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (target.name == "messageText") {
            const remaining = 160 - (this.state.messageText.length + 1);
            this.setState({
                remainingCharacters: remaining > 0 ? remaining : 0,
            });
        }
        this.setState({
            [name]: value,
        });
    }
    validateForm() {
        let formValid = true;

        if (this.state.messageText == "") {
            this.setState({ remainingCharErr: "Ве молиме внесете текст" });
            formValid = false;
        } else if (this.state.remainingCharacters < 0) {
            this.setState({
                remainingCharErr: "Максимум број на дозволени карактери е 160",
            });
            formValid = false;
        }
        if (this.state.numbersFileName == '') {
            this.setState({ fileError: 'Ве молиме одберете датотека со броеви' })
            formValid = false
        }
        if (this.state.tagLabel == "") {
            this.setState({ tagLabelErr: "Ве молиме внесете ознака за кампања" });
            formValid = false;
        }
        return formValid;
    }

    async onChangeHandler(event) {
        try {
            const target = event.target.files[0].name.slice(
                ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
            )
            if (target.toLowerCase() == 'xlsx') {
                const data = new FormData()
                data.append('file', event.target.files[0])
                var response = await UsersApi.uploadNumbers(data)
                console.log(response)
                this.setState({ numbersFileName: response.data.filename, fileError: '' })
            } else {
                this.setState({
                    fileError: 'Неправилен формат на фајл. Форматот мора да биде .xlsx',
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    render() {
        return (
            <Row className="table-section">
                <Col>
                    <Form>
                        <Form.Group as={Row}>
                            <Col sm="10">
                                <Col md={12}>
                                    <Form.Label column sm="6" className="heading bold-text">
                                        Внесете текст
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="10"
                                        name="messageText"
                                        onChange={this.handleChange}
                                        className="text-area"
                                        value={this.state.messageText}
                                    />
                                    <Col md={12} className="mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <p className="error">{this.state.remainingCharErr}</p>
                                            </Col>
                                            <Col md={6}>
                                                <label
                                                    className="col-md-12 mt-2 pull-right"
                                                    style={{ textAlign: "right" }}
                                                >
                                                    Преостанати карактери {this.state.remainingCharacters}. Пораката мора да е напишана на латиница.
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>


                                </Col>
                                <Col xs={12} md={6} className="mt-4">
                                    <label className="bold-text">Ознака на кампања</label>
                                    <FormControl
                                        type="text"
                                        placeholder="Внесете ознака на кампањата"
                                        onChange={this.handleChange}
                                        name="tagLabel"
                                        value={this.state.tagLabel}
                                    ></FormControl>
                                    <p className="error">{this.state.tagLabelErr}</p>
                                </Col>
                                <Col>
                                    <label className="col-md-12 pl-0 bold-text">
                                        Одберeте датотека со броеви
                                    </label>
                                    <input
                                        type="file"
                                        name="numbersFileName"
                                        onChange={this.onChangeHandler}
                                        key={this.state.inputFileKey || '' }
                                        value={this.state.selectedFile}
                                    />
                                    <p className="error">{this.state.fileError}</p>
                                </Col>
                                <Col xs={12} md={6} className="mt-4">
                                    <Button
                                        variant="outline-warning mb-3 mt-3"
                                        onClick={this.SendBulk}
                                        sm="3"
                                        size="sm"
                                        className="margin-top-10px button-standard"
                                    >
                                        Испрати
                                    </Button>
                                </Col>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}></Form.Group>
                    </Form>
                </Col>
            </Row>
        );
    }
}
