import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ViberBulkReport from "./ViberBulkReport";
import SmsBulkStatistics from "./SmsBulkStatistics";
import ViberFinalChatBot from "./ViberFinalChatbots";
import ViberFinalReport from "./ViberFinalReport";

export default () => (
  <Tabs>
    <TabList>
      <Tab>СМС</Tab>
      <Tab>Вибер</Tab>
      <Tab>Sparky-Вибер Бот</Tab>
      <Tab>Вибер Кампањи</Tab>
    </TabList>

    <TabPanel>
      <SmsBulkStatistics />
    </TabPanel>
    <TabPanel>
      <ViberBulkReport></ViberBulkReport>
    </TabPanel>
    <TabPanel>
      <ViberFinalChatBot></ViberFinalChatBot>
    </TabPanel>
    <TabPanel>
      <ViberFinalReport></ViberFinalReport>
    </TabPanel>
  </Tabs>
);
