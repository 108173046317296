import React from "react";
import ReactExport from "react-export-excel";
import ApplicationAPi from "../api/visaApplications-api";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadApplications extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      applications: [],
      dateFrom: new Date(),
      dateTo: new Date(),
    };
  }

  async componentDidMount() {
    try {
      let dateFrom = new Date();
      dateFrom.setMonth(this.props.dateFrom.getUTCMonth());
      dateFrom.setFullYear(this.props.dateFrom.getUTCFullYear());
      dateFrom.setDate(this.props.dateFrom.getDate());
      dateFrom.setHours(0, 0, 0, 0);

      let dateTo = new Date();
      dateTo.setMonth(this.props.dateTo.getUTCMonth());
      dateTo.setFullYear(this.props.dateTo.getUTCFullYear());
      dateTo.setDate(this.props.dateTo.getDate());
      dateTo.setHours(23, 59, 59, 59);
      const result = await ApplicationAPi.getForExport(dateFrom, dateTo);
      this.setState({ applications: result.applications });
    } catch (error) {
      throw error;
    }
  }

  async componentWillReceiveProps(props) {
    try {
      let dateFrom = new Date();
      dateFrom.setMonth(props.dateFrom.getUTCMonth());
      dateFrom.setFullYear(props.dateFrom.getUTCFullYear());
      dateFrom.setDate(props.dateFrom.getDate());
      dateFrom.setHours(0, 0, 0, 0);

      let dateTo = new Date();
      dateTo.setMonth(props.dateTo.getUTCMonth());
      dateTo.setFullYear(props.dateTo.getUTCFullYear());
      dateTo.setDate(props.dateTo.getDate());
      dateTo.setHours(23, 59, 59, 59);
      const result = await ApplicationAPi.getForExport(dateFrom, dateTo);
      this.setState({ applications: result.applications });
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename="VISA Аппликации"
        element={
          <Button variant="btn mr-2 button-standard">Експортирај</Button>
        }
      >
        {this.state && this.state.applications != null ? (
          <ExcelSheet data={this.state.applications} name="users">
            <ExcelColumn label="Име" value="first_name" />
            <ExcelColumn label="Презиме" value="last_name" />
            <ExcelColumn label="Емаил" value="email" />
            <ExcelColumn label="Телефон" value="phone" />
            <ExcelColumn label="ЕМБГ" value="embg" />
            <ExcelColumn label="Експозитура" value="office" />
            <ExcelColumn label="Пакет" value="product" />
            <ExcelColumn label="Дата" value="created" />
            <ExcelColumn label="Статус" value="status" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default DownloadApplications;
