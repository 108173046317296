import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { Row, Col, Form, FormControl, Button, Table, Card, Container, ListGroup, } from 'react-bootstrap';
import ReportsApi from '../../api/reports-api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-date-picker'
import reportsApi from '../../api/reports-api'
import Select from 'react-select'
import { convertToLocalDateFormat } from '../../helpers/date-helpers'
import SmsBulkExport from '../../components/Reports/Exports/SmsBulkExport'
import { FunnelChart } from 'react-funnel-pipeline'

export default class SmsBulkStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignNames: [],
            campaign: null,
            tag: null,
            smsText: '',
            searchText: '',
            dateFrom: new Date(),
            dateTo: new Date(),
            numberofMessagesPerCampaign: 0,
            numberOfDelivered: 0,
            numberOfErrors: 0,
            numberOfAllMessages: 0,
            numberOfFailed: 0,
            campaignLabel: '',


        };
        this.tagChange = this.tagChange.bind(this)
        this.getCampaignNames = this.getCampaignNames.bind(this)
        this.onDateFromChange = this.onDateFromChange.bind(this)
        this.onDateToChange = this.onDateToChange.bind(this)

    }

    async onDateFromChange(e) {
        this.setState({ dateFrom: e })
    }

    async onDateToChange(e) {
        this.setState({ dateTo: e })
    }

    async getCampaignNames() {
        try {
            const result = await ReportsApi.getSmsCampaignNames(
                this.state.dateFrom,
                this.state.dateTo,
            );
            this.setState({
                campaignNames: result,
            });
            console.log(this.state.campaignNames);

        } catch (error) {
            console.log(error);
            toast.error('Грешка при земањето на кампањи.');
        }
    }

    async tagChange(e) {
        var tag = await reportsApi.getSmsCampaign(e.label)
        if (tag) {
            this.setState({
                tag: tag,
                numberOfDelivered: tag.successfulCount,
                numberOfErrors: tag.errorCount,
                numberOfAllMessages: tag.totalSmsCount,
                numberofMessagesPerCampaign: tag.campaignTotalCount,
                numberOfFailed: tag.failedCount,
                campaignText: tag.campaignText,
            })
        }
    }

    render() {
        return (
            <Container>
                <Row className="mb-4">
                    <Col>
                        <Select
                            options={this.state.campaignNames}
                            isSearchable="true"
                            onChange={this.tagChange}
                            placeholder="Одберете кампања"
                        ></Select>
                    </Col>
                    <Col>
                        <span>Дата Од </span>
                        <DatePicker
                            value={this.state.dateFrom}
                            onChange={this.onDateFromChange}
                            format="dd-MM-yyyy"
                            disableClock={true}
                            timeFormat={false}
                        />
                    </Col>
                    <Col>
                        <span>Дата До </span>
                        <DatePicker
                            value={this.state.dateTo}
                            onChange={this.onDateToChange}
                            format="dd-MM-yyyy"
                            disableClock={true}
                            timeFormat={false}
                        />
                    </Col>
                    <Col>
                        <Button
                            variant='outline-dark mb-3 mt-3'
                            onClick={() => this.getCampaignNames()}
                        >
                            Пребарај
                        </Button>
                    </Col>
                </Row>
                <hr />
                {this.state.tag ? (
                    <Row>
                        <Row style={{ width: '100%' }} className="mb-4">
                            <Card style={{ width: '100%' }} className="report-summury-card">
                                <ListGroup horizontal style={{ width: '100%' }}>
                                    <ListGroup.Item style={{ width: '60%' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="bold-text">
                                                {this.state.tag ? this.state.tag.tag : null}
                                            </p>
                                            <p>
                                                {' '}
                                                {this.state.tag
                                                    ? convertToLocalDateFormat(this.state.tag.date)
                                                    : null}
                                            </p>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        style={{ width: '20%' }}
                                        className="reports-card-right-info"
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="bold-text">
                                                {this.state.tag
                                                    ? this.state.numberofMessagesPerCampaign
                                                    : null}
                                            </p>
                                            <p> Вкупно Испратени</p>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item style={{ width: '20%' }}>
                                        <SmsBulkExport campaignLabel={this.state.tag.campaignLabel} />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={12} md={4}>
                                <FunnelChart
                                    data={[
                                        { name: 'Деливирани', value: this.state.numberOfDelivered },
                                        { name: 'Недоставени', value: this.state.numberOfFailed },
                                        // { name: 'Непрочитани', value: this.state.numberOfUnSeen },
                                        { name: 'Грешки', value: this.state.numberOfErrors },
                                    ]}
                                    pallette={['#78E2B1', '#34CE85', '#2AB772', '#29A465']}
                                    getRowNameStyle={(row) => {
                                        return row.value == 999999
                                            ? { color: '#BCE4FA' }
                                            : { color: 'white' }
                                    }}
                                    getRowValueStyle={(row) => {
                                        return row.value == 999999
                                            ? { color: '#BCE4FA' }
                                            : { color: 'white' }
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <Row>
                                    <Card
                                        className="reports-card"
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="reports-card-left-info">
                                                {this.state.numberOfDelivered != 0
                                                    ? (this.state.numberOfDelivered /
                                                        this.state.numberofMessagesPerCampaign).toFixed(4) *
                                                    100
                                                    : 0}{' '}
                                                %
                                            </ListGroup.Item>
                                            <ListGroup.Item className="reports-card-right-info">
                                                Деливирани: {this.state.numberOfDelivered}
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card
                                        className="reports-card"
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="reports-card-left-info">
                                                {this.state.numberOfFailed != 0
                                                    ? (this.state.numberOfFailed /
                                                        this.state.numberofMessagesPerCampaign).toFixed(4) *
                                                    100
                                                    : 0}{' '}
                                                %
                                            </ListGroup.Item>
                                            <ListGroup.Item className="reports-card-right-info">
                                                Погрешен број: {this.state.numberOfFailed}
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Row>
                                <Row>
                                    <Card
                                        className="reports-card"
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <ListGroup horizontal>
                                            <ListGroup.Item className="reports-card-left-info">
                                                {this.state.numberOfErrors != 0
                                                    ? (this.state.numberOfErrors /
                                                        this.state.numberofMessagesPerCampaign).toFixed(4) *
                                                    100
                                                    : 0}{' '}
                                                %
                                            </ListGroup.Item>
                                            <ListGroup.Item className="reports-card-right-info">
                                                Недоставени: {this.state.numberOfErrors}
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                {this.state.tag ? (
                                    <Row>
                                        <Card style={{ backgroundColor: '#EFEFEF' }}>
                                            <Card.Body>
                                                <div className="mb-4">
                                                    <span className="bold-text">Текст</span> :{' '}
                                                    {this.state.tag.campaignText}{' '}
                                                </div>
                                                { <div className="mb-4">
                                                    <span className="bold-text">Датум</span> :{' '}
                                                    {convertToLocalDateFormat(this.state.tag.date)} {' '}
                                                </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Row>
                                ) : null}
                            </Col>
                        </Row>
                    </Row>
                ) : null}
            </Container>


        )
    }

}

