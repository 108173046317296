import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import VisaApplicationApi from "../api/visaApplications-api";
import DatePicker from "react-date-picker";
import DownloadApplications from "./DownloadApplications";

export default class VisaApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      searchText: "",
      applications: null,
      dateFrom: new Date(),
      dateTo: new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendCoupon = this.sendCoupon.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.resetApplication = this.resetApplication.bind(this);
    this.discardApplication = this.discardApplication.bind(this);
    this.resendCoupon = this.resendCoupon.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  async handlePageClick(e) {
    await this.getApplications(e.selected + 1);
  }

  onDateFromChange(e) {
    this.setState({ dateFrom: e });
  }

  onDateToChange(e) {
    this.setState({ dateTo: e });
  }

  async sendCoupon(application_id, viber_id) {
    try {
      var result = await VisaApplicationApi.sendCoupon(
        application_id,
        viber_id
      );
      if (result.status == 200) {
        toast.success("Ваучерот беше успешно доделен");
        this.getApplications();
      }
    } catch (error) {
      if ((error.message = "No coupons left!")) {
        toast.warn("Нема повеќе слободни ваучери");
        return;
      }
      toast.error("Грешка при испраќањето на ваучерот");
      throw error;
    }
  }

  async resendCoupon(application_id, viber_id) {
    try {
      var result = await VisaApplicationApi.resendCoupon(
        application_id,
        viber_id
      );
      if (result.status == 200) {
        toast.success("Ваучерот беше успешно препратен");
        this.getApplications();
      }
    } catch (error) {
      toast.error("Грешка при препраќањето на ваучерот");
      throw error;
    }
  }

  async resetApplication(application_id) {
    try {
      await VisaApplicationApi.resetApplication(application_id);
      toast.success("Апликацијата беше успешно ресетирана");
      this.getApplications();
    } catch (error) {
      toast.error("Грешка при ресетирање");
      throw error;
    }
  }

  async discardApplication(application_id) {
    try {
      await VisaApplicationApi.discardApplication(application_id);
      toast.success("Аппликацијата е одбиена");
      this.getApplications();
    } catch (error) {
      toast.error("Грешка при одбивање");
      throw error;
    }
  }

  async getApplications(page = 0) {
    try {
      var applicationsResult = await VisaApplicationApi.getAll(
        page === 0 ? this.state.page : page,
        10,
        this.state.searchText
      );
      this.setState({ applications: applicationsResult.applications });
    } catch (error) {
      toast.error("Грешка при преземањето на аппликациите");
      throw error;
    }
  }
  async componentDidMount() {
    await this.getApplications();
  }

  renderStatus(statusId) {
    switch (statusId) {
      case 1:
        return "Не прегледано";
      case 2:
        return "Испратен ваучер";
      case 3:
        return "Одбиена";
      case 4:
        return "Ресетирана";
      case 5:
        return "Испратен наново";
    }
  }

  render() {
    return (
      <Row className="table-section">
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">VISA аппликации</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type="text"
              name="searchText"
              placeholder="Пребарај"
              className="mr-sm-2"
              onChange={this.handleChange}
            />
            <Button
              variant="btn mr-2 button-standard"
              onClick={() => this.getApplications()}
            >
              <i className="material-icons">search</i>
              Пребарај
            </Button>
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getApplications()}
            >
              <i className="material-icons">refresh</i> Освежи
            </Button>
            <Card className="transparent">
              <Card.Body>
                <p>Дата од</p>
                <DatePicker
                  className="mb-2"
                  value={this.state.dateFrom}
                  onChange={this.onDateFromChange}
                  format="dd-MM-yyyy"
                  disableClock={true}
                  timeFormat={false}
                />
                <p>Дата до</p>
                <DatePicker
                  className="mr-2"
                  value={this.state.dateTo}
                  onChange={this.onDateToChange}
                  format="dd-MM-yyyy"
                  disableClock={true}
                  timeFormat={false}
                />
                <DownloadApplications
                  className="ml-2"
                  dateFrom={this.state.dateFrom}
                  dateTo={this.state.dateTo}
                />
              </Card.Body>
            </Card>
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Име</th>
                  <th>Презиме</th>
                  <th>ЕМБГ</th>
                  <th>Телефон</th>
                  <th>Емаил</th>
                  <th>Експозитура</th>
                  <th>Продукт</th>
                  <th>Креиран на</th>
                  <th>Статус</th>
                  <th>Акции</th>
                </tr>
              </thead>
              <tbody>
                {this.state.applications
                  ? this.state.applications.rows.map((i) => {
                      let date = new Date(i.created).toLocaleString("en-GB");
                      console.log(i.copuon_sent);
                      return (
                        <tr key={i._id}>
                          <td>{i.first_name}</td>
                          <td>{i.last_name}</td>
                          <td>{i.embg}</td>
                          <td>{i.phone}</td>
                          <td>{i.email}</td>
                          <td>{i.office}</td>
                          <td>{i.product}</td>
                          <td>{date}</td>
                          <td>{this.renderStatus(i.status)}</td>
                          <td>
                            {i.status === 1 || i.status === 4 ? (
                              <div className="form-group md-4">
                                <Button
                                  className="form-control btn btn-xs mb-1 btn-success"
                                  onClick={() =>
                                    this.sendCoupon(i._id, i.viber_id)
                                  }
                                >
                                  Испрати
                                </Button>
                                <Button
                                  className="form-control btn btn-xs btn-info mb-1"
                                  onClick={() => this.resetApplication(i._id)}
                                >
                                  Ресетирај
                                </Button>
                                <Button
                                  className="form-control btn btn-xs btn-danger mb-1"
                                  onClick={() => this.discardApplication(i._id)}
                                >
                                  Одби
                                </Button>
                              </div>
                            ) : i.status == 2 ? (
                              <Button
                                className="btn btn-info"
                                onClick={() =>
                                  this.resendCoupon(i._id, i.viber_id)
                                }
                              >
                                Прати повторно
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"< Претходна"}
              nextLabel={"Следна >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.applications && this.state.applications.count
                  ? Math.ceil(this.state.applications.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link z-index-0"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
              className="z-index-0"
            />
          </section>
        </Col>
      </Row>
    );
  }
}
