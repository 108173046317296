import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { getMonthName } from "../../utils/dates";

import "react-funnel-pipeline/dist/index.css";
import reportsApi from "../../api/reports-api";

export default class ViberFinalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 2022, // TODO get distinct year from DB
      reports: [],
    };
  }

  async getReports() {
    try {
      const reportsResult = await reportsApi.getViberFinalReports(
        this.state.year
      );
      this.setState({ reports: reportsResult });
    } catch (error) {}
  }
  async componentDidMount() {
    await this.getReports();
  }

  render() {
    const options = [{ value: 2022, label: 2022 }];
    return (
      <Container>
        <Row className="mb-4">
          <Col>
            <Select options={options} placeholder="Одберете година"></Select>
          </Col>
        </Row>
        <hr />
        {this.state.reports
          ? this.state.reports.map((r) => {
              return (
                <section className="list-box mt-3">
                  <h3>{getMonthName(r.month)}</h3>
                  <Table className="mb-5 table-x-scroll">
                    <thead className="table-head text-center">
                      <tr>
                        <th>Име на кампања</th>
                        <th>Број на таргет клиент</th>
                        <th>Број на испратени ВИБЕР пораки</th>
                        <th>Број на испраени СМС пораки</th>
                        <th>Број на успешно испратени ВИБЕР пораки</th>
                        <th>%</th>
                        <th>Број на успешно испратени смс пораки</th>
                        <th>%</th>
                        <th>Вкупно</th>
                        <th>%</th>
                        <th>Број на прочитани вибер пораки</th>
                        <th>%</th>
                        <th>Број на кликови на линк</th>
                        <th>%</th>
                        <th>
                          Број на корисници кои блокирале добивање пораки од ШБС
                        </th>
                        <th>%</th>
                        <th>Број на клиенти кои не се корисници на Viber</th>
                        <th>%</th>
                        <th>Број на клиенти кои имаат несоодветен уред</th>
                        <th>%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {r.reports.map((i) => {
                        return (
                          <tr key={i.month} className="text-center">
                            <td>{i.tag_name}</td>
                            <td>{i.target_no}</td>
                            <td>{i.sent_no}</td>
                            <td>{i.sms_sent_no}</td>
                            <td>{i.success_no}</td>
                            <td>{i.success_perc.toFixed(2)}</td>
                            <td>{i.success_sms}</td>
                            <td>{i.success_sms_perc}</td>
                            <td>{i.sum}</td>
                            <td>{i.sum_perc}</td>
                            <td>{i.seen}</td>
                            <td>{i.seen_perc}</td>
                            <td>{i.clicks_no}</td>
                            <td>/</td>
                            <td>{i.blocked_from_user}</td>
                            <td>{i.blocked_from_user_perc}</td>
                            <td>{i.not_a_viber_user}</td>
                            <td>{i.not_a_viber_user_perc}</td>
                            <td>{i.not_suitable_device}</td>
                            <td>{i.not_suitable_device_perc}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </section>
              );
            })
          : null}
      </Container>
    );
  }
}
