import Axios from "axios";
import Cookies from "js-cookie";
class VisaAppplicationsApi {
  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/all`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/all`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }
  async sendCoupon(applicationID, viber_id) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/send-coupon`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/send-coupon`,
        { applicationID, viber_id },
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }

  async getForExport(dateFrom, dateTo) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/get-for-export`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/get-for-export`,
        { dateFrom, dateTo },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async resetApplication(applicationId) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/reset-application/${applicationId}`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/reset-application/${applicationId}`,
        null,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async discardApplication(applicationId) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/discard-application/${applicationId}`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/discard-application/${applicationId}`,
        null,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async resendCoupon(applicationID, viber_id) {
    const token = Cookies.get("jwt");
    const user = Cookies.get("currentUser");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/visa-applications/resend-coupon`
          : `${process.env.REACT_APP_LOCAL_API_URL}/visa-applications/resend-coupon`,
        { applicationID, viber_id, user },
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }
}

export default new VisaAppplicationsApi();
