import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import NumbersFile from "./Bulk/NumbersFile";
import messagesApi from "../api/messages.api";
import { toast } from "react-toastify";
import UsersApi from "../api/users.api";
import RichTextEditor from "react-rte";
import spinner from "../images/spinner.gif";

export default class EmailBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageText: "",
      subject: "",
      subjectErr: null,
      selectedFile: null,
      attachment: null,
      subjectLabelErr: "",
      numbersFileName: "",
      attachmentsFileName: "",
      fileError: null,
      inputFileKey: "",
      attachmentKey: "",
      remainingCharErr: "",
      value: RichTextEditor.createEmptyValue(),
      isLoading: false,
    };
    this.SendBulk = this.SendBulk.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
  }
  async SendBulk() {
    try {
      if (this.validateForm()) {
        this.setState({ isLoading: true });
        var response = await messagesApi.sendEmailBulk({
          html: this.state.value.toString("html"),
          subject: this.state.subject,
          fileName: this.state.numbersFileName,
          attachmentsFileName: this.state.attachmentsFileName,
        });
        this.setState({
          messageText: "",
          subject: "",
          selectedFile: null,
          numbersFileName: "",
          fileError: null,
          subjectErr: null,
          inputFileKey: "reset",
        });
        toast.success("Пораката беше испратена");
        this.setState({ isLoading: false });
      }
    } catch (error) {
      toast.success("Грешка при испраќање на пораката");
    }
  }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  validateForm() {
    let formValid = true;
    console.log(this.state.value.toString("html"));
    if (this.state.value.toString("html") == "<p><br></p>") {
      this.setState({ remainingCharErr: "Ве молиме внесете текст" });
      formValid = false;
    }
    if (this.state.numbersFileName == "") {
      this.setState({ fileError: "Ве молиме одберете датотека со емаилови" });
      formValid = false;
    }
    if (this.state.subject == "") {
      this.setState({ subjectLabelErr: "Ве молиме внесете наслов" });
      formValid = false;
    }
    return formValid;
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (target.toLowerCase() == "xlsx") {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        var response = await UsersApi.uploadNumbers(data);
        console.log(response);
        this.setState({
          numbersFileName: response.data.filename,
          fileError: "",
        });
      } else {
        this.setState({
          fileError: "Неправилен формат на фајл. Форматот мора да биде .xlsx",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async onFileUpload(event) {
    try {
      console.log("vee");
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const data = new FormData();
      data.append("file", event.target.files[0]);
      var response = await UsersApi.uploadNumbers(data);
      this.setState({
        attachmentsFileName: response.data.filename,
        fileError: "",
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Row className="table-section">
        {this.state.isLoading == true ? (
          <div className="center">
            <img src={spinner}></img>
          </div>
        ) : (
          <Col>
            <Form>
              <Form.Group as={Row}>
                <Col sm="10">
                  <Col md={12}>
                    <Form.Label column sm="6" className="heading bold-text">
                      Внесете текст
                    </Form.Label>
                    <RichTextEditor
                      rootStyle={{
                        fontFamily: "Arial",
                        fontSize : "12px"
                      }}
                      value={this.state.value}
                      onChange={this.onChange}
                    />
                    <p className="error">{this.state.remainingCharErr}</p>
                  </Col>
                  <Col xs={12} md={6} className="mt-4">
                    <label className="bold-text">Наслов</label>
                    <FormControl
                      type="text"
                      placeholder="Внесете наслов"
                      onChange={this.handleChange}
                      name="subject"
                      value={this.state.subject}
                    ></FormControl>
                    <p className="error">{this.state.subjectLabelErr}</p>
                  </Col>
                  <Col>
                    <br />
                    <label className="col-md-12 pl-0 bold-text">
                      Одберете прилог
                    </label>
                    <input
                      type="file"
                      name="attachmentsFileName"
                      onChange={this.onFileUpload}
                      key={this.state.attachmentKey || ""}
                      value={this.state.attachment}
                    />
                  </Col>
                  <Col>
                    <br />
                    <label className="col-md-12 pl-0 bold-text">
                      Одберeте датотека со емаилови
                    </label>
                    <input
                      type="file"
                      name="numbersFileName"
                      onChange={this.onChangeHandler}
                      key={this.state.inputFileKey || ""}
                      value={this.state.selectedFile}
                    />
                    <p className="error">{this.state.fileError}</p>
                  </Col>
                  <Col xs={12} md={6} className="mt-4">
                    <Button
                      variant="outline-warning mb-3 mt-3"
                      onClick={this.SendBulk}
                      sm="3"
                      size="sm"
                      className="margin-top-10px button-standard"
                    >
                      Испрати
                    </Button>
                  </Col>
                </Col>
              </Form.Group>
              <Form.Group as={Row}></Form.Group>
            </Form>
          </Col>
        )}
      </Row>
    );
  }
}
