import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import messagesApi from "../../api/messages.api";
import { toast } from "react-toastify";
import UsersApi from "../../api/users.api";
import { MessageTypes } from "../../common/enums";
import UploadsAPI from "../../api/uploads.api";

export default class CombinedBulk extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      numbersFileName: "",
      fileError: null,
      bulkOption: 0,
      selectedimage: null,
      imageFileErr: null,
      imageFileName: "",
      smsText: "",
      remainingCharacters: 160,
      messageText: "",
      buttonText: "",
      buttonUrl: "",
      tagLbl: "",
      tagLblError: "",
      remainingCharErr: null,
      remainingCharErrSms: null,
      viberRemaining: 1000,
      viberCharErr : "",
    };
    this.SendBulk = this.SendBulk.bind(this);
    this.onChangeimageHandler = this.onChangeimageHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  validateForm() {
    let formValid = true; 
    if (this.state.messageText == "") {
      this.setState({ remainingCharErr: "Ве молиме внесете текст" });
      formValid = false;
    } else if (this.state.viberRemaining <= 0) {
      console.log('err')
      this.setState({
        viberCharErr: "Максимум број на дозволени карактери е 1000",
      });
      formValid = false;
    }
    if (this.state.smsText == "") {
      this.setState({ remainingCharErrSms: "Ве молиме внесете текст" });
      formValid = false;
    } else if (this.state.remainingCharacters <= 0) {
      this.setState({
        remainingCharErrSms: "Максимум број на дозволени карактери е 160",
      });
      formValid = false;
    }
    if (this.state.numbersFileName == "") {
      this.setState({ fileError: "Ве молиме одберете датотека со броеви" });
      formValid = false;
    }

    if (this.state.tagLbl == "") {
      this.setState({
        tagLblError: "Внесете ознаkа на кампања",
      });
      formValid = false;
    }

    return formValid;
  }
  messageLabelChange(event) {
    this.setState({ messageLabelType: event.value });
  }

  async onChangeimageHandler(event) {
    try {
      console.log(event.target);
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      console.log(target);
      if (target.toLowerCase() == "png" || target.toLowerCase() == "jpg") {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        var response = await UploadsAPI.uploadImage(data);
        this.setState({ imageFileName: response.data.filename, fileError: "" });
      } else {
        this.setState({
          imageFileErr:
            "Неправилен формат на фајл. Форматот мора да биде .png или .jpg",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.name == "smsText") {
      const remaining = 160 - (this.state.smsText.length + 1);
      this.setState({
        remainingCharacters: remaining > 0 ? remaining : 0,
      });
    } else if (target.name == "messageText") {      
      const remaining = 1000 - (this.state.messageText.length + 1);
      this.setState({
        viberRemaining: remaining > 0 ? remaining : 0,
      });
    }
    this.setState({
      [name]: value,
    });
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (target.toLowerCase() == "xlsx") {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        var response = await UsersApi.uploadNumbers(data);
        console.log(response);
        this.setState({
          numbersFileName: response.data.filename,
          fileError: "",
        });
      } else {
        this.setState({
          fileError: "Неправилен формат на фајл. Форматот мора да биде .xlsx",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async SendBulk() {
    this.setState({
      fileError: null,
      imageFileErr: null,
      tagLblError: "",
      remainingCharErr: null,
      remainingCharErrSms: null,
    });
    if (this.validateForm()) {
      await messagesApi.sendBulkCombined({
        text: this.state.messageText,
        imageUrl: this.state.imageFileName
          ? `${process.env.REACT_APP_IMAGES_URL}/${this.state.imageFileName}`
          : null,
        fileName: this.state.numbersFileName,
        buttonText: this.state.buttonText,
        buttonUrl: this.state.buttonUrl,
        tagLbl: this.state.tagLabel,
        messageType: MessageTypes.TextImageButton,
        smsText: this.state.smsText,
      });
      this.setState({
        selectedFile: null,
        numbersFileName: "",
        fileError: null,
        bulkOption: 0,
        selectedimage: null,
        imageFileErr: null,
        imageFileName: null,
        smsText: "",
        remainingCharacters: 160,
        messageText: "",
        buttonText: "",
        buttonUrl: "",
        tagLbl: "",
        tagLblError: "",
        remainingCharErr: null,
        remainingCharErrSms: null,
      });
      toast.success("Пораката беше испратена");
    }
  }

  render() {
    return (
      <Col>
        <Form>
          <Form.Group as={Row}>
            <Col sm="10">
              <Col md={12}>
                <Form.Label column sm="6" className="heading bold-text">
                  Внесете текст VIBER
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="10"
                  name="messageText"
                  onChange={this.handleChange}
                  className="text-area"
                  value={this.state.messageText}
                />
                <Col md={12} className="mt-4">
                  <Row>
                    <Col md={6}>
                      <p className="error">{this.state.viberCharErr}</p>
                    </Col>
                    <Col md={6}>
                      <label
                        className="col-md-12 mt-2 pull-right"
                        style={{ textAlign: "right" }}
                      >
                        Преостанати карактери {this.state.viberRemaining}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={12}>
                <Form.Label column sm="6" className="heading bold-text">
                  Внесете текст SMS
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="10"
                  name="smsText"
                  onChange={this.handleChange}
                  className="text-area"
                  value={this.state.smsText}
                />
                <Col md={12} className="mt-4">
                  <Row>
                    <Col md={6}>
                      <p className="error">{this.state.remainingCharErrSms}</p>
                    </Col>
                    <Col md={6}>
                      <label
                        className="col-md-12 mt-2 pull-right"
                        style={{ textAlign: "right" }}
                      >
                        Преостанати карактери {this.state.remainingCharacters}.
                        Пораката мора да е напишана на латиница.
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Одберeте слика
                </label>
                <input
                  type="file"
                  name="imageFileName"
                  onChange={this.onChangeimageHandler}
                  value={this.state.selectedimage}
                />
                <p className="error">{this.state.imageFileErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">URL-адреса копче</label>
                <FormControl
                  type="text"
                  placeholder="Внесете URL-адреса на копчето"
                  onChange={this.handleChange}
                  name="buttonUrl"
                  value={this.state.buttonUrl}
                ></FormControl>
                <p className="error">{this.state.buttonUrlErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Текст на копче</label>
                <FormControl
                  type="text"
                  placeholder="Внесете текст на копчето"
                  onChange={this.handleChange}
                  name="buttonText"
                  value={this.state.buttonText}
                ></FormControl>
                <p className="error">{this.state.buttonTextErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Ознака на кампања</label>
                <FormControl
                  type="text"
                  placeholder="Внесете ознака на кампањата"
                  onChange={this.handleChange}
                  name="tagLbl"
                  value={this.state.tagLbl}
                ></FormControl>
                <p className="error">{this.state.tagLblError}</p>
              </Col>
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Одберeте датотека со броеви
                </label>
                <input
                  type="file"
                  name="numbersFileName"
                  onChange={this.onChangeHandler}
                  value={this.state.selectedFile}
                />
                <p className="error">{this.state.fileError}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <Button
                  variant="outline-warning mb-3 mt-3"
                  onClick={this.SendBulk}
                  sm="3"
                  size="sm"
                  className="margin-top-10px button-standard"
                >
                  Испрати
                </Button>
              </Col>
            </Col>
          </Form.Group>
          <Form.Group as={Row}></Form.Group>
        </Form>
      </Col>
    );
  }
}
