import React from 'react';
import ReactExport from 'react-export-excel';
import usersApi from '../api/users.api';
import { Button } from 'react-bootstrap';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      users: []
    };
  }

  async componentDidMount() {
    try {
      const usersArr = await usersApi.getAllForExport();
      this.setState({ users: usersArr });
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename='Корисници'
        element={<Button variant='btn mr-2 button-standard'>Експортирај</Button>}
      >
        {this.state && this.state.users != null ? (
          <ExcelSheet data={this.state.users} name='users'>
            <ExcelColumn label='Вибер име' value='viber_name'/>
            <ExcelColumn label='Име и презиме' value='name'/>
            <ExcelColumn label='Емаил' value='email'/>
            <ExcelColumn label='Адреса' value='address'/>
            <ExcelColumn label='Телефон' value='phone'/>
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default Download;
