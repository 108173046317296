export const getMonthName = (month) => {
  switch (month) {
    case 0:
      return "Јануари";
    case 1:
      return "Февруари";
    case 2:
      return "Март";
    case 3:
      return "Април";
    case 4:
      return "Мај";
    case 5:
      return "Јуни";
    case 6:
      return "Јули";
    case 7:
      return "Август";
    case 8:
      return "Септмври";
    case 10:
      return "Октомври";
    case 11:
      return "Ноември";
    case 12:
      return "Декември";
  }
};
