import Axios from "axios";
import Cookies from "js-cookie";

class BranchesApi{
    async getAllBranches(pageNumber, totalCount, searchText) {
        const token = Cookies.get("jwt");
        const configAPi = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
    
        try {
          const r = await Axios.post( 
            process.env.REACT_APP_STAGE === "production"
              ? `${process.env.REACT_APP_API_BASE_URL}/branches/all`
              : `${process.env.REACT_APP_LOCAL_API_URL}/branches/all`,
            { pageNumber, totalCount, searchText },
            configAPi
          );
          return r.data;
        } catch (err) {
          throw err;
        }
      }

      async editBranch(branchId, email, director) {
        try {
          const token = Cookies.get("jwt");
          const configAPi = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          await Axios.post(
            process.env.REACT_APP_STAGE === "production"
              ? `${process.env.REACT_APP_API_BASE_URL}/branches/edit-branch`
              : `${process.env.REACT_APP_LOCAL_API_URL}/branches/edit-branch`,
            { branchId, email, director },
            configAPi
          );
        } catch (error) {
          throw error;
        }
      }
}

export default new BranchesApi();


