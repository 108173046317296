import React from 'react'
import ReactExport from 'react-export-excel'
import reportsApi from '../../api/reports-api'
import { Button } from 'react-bootstrap'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class DownloadBotStatistics extends React.Component {
  constructor(props) {
    super(props)

    this.sate = {
      statistics: [],
    }
  }

  async componentDidMount() {
    try {
      const result = await reportsApi.getViberStatistics()
      this.setState({ statistics: result })
    } catch (error) {
      throw error
    }
  }

  render() {
    return (
      <ExcelFile
        filename="Статистика"
        element={
          <Button variant="btn mr-2 button-standard">Експортирај</Button>
        }
      >
        {this.state && this.state.statistics != null ? (
          <ExcelSheet data={this.state.statistics} name="statistics">
            <ExcelColumn label="Акција" value="action" />
            <ExcelColumn label="Посети" value="visits" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    )
  }
}
export default DownloadBotStatistics
