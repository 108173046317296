import React from "react";
import ReactExport from "react-export-excel";
import meetingsApi from "../api/meetings.api";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportMeetingsAll extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      meetingsP: [],
      meetingsL: [],
    };
  }

  async componentDidMount() {
    try {
      const meetings = await meetingsApi.getAllForExport();
      console.log("Meeetings", meetings);

      this.setState({
        meetingsP: meetings.meetingsForExport.physicalArr,
        meetingsL: meetings.meetingsForExport.legalArr,
      });
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename="Состаноци"
        element={
          <Button variant="btn mr-2 button-standard">
            <i className="material-icons">cloud_download</i>Експортирај
          </Button>
        }
      >
        {this.state && this.state.meetingsP != null ? (
          <ExcelSheet data={this.state.meetingsP} name="Физички лица">
            <ExcelColumn label="Состанок со" value="meeting_with" />
            <ExcelColumn label="Тип на производ" value="serviceType" />
            <ExcelColumn label="Експозитура" value="branchOffice" />
            <ExcelColumn label="Дата" value="date" />
            <ExcelColumn label="Телефон" value="phone" />
            <ExcelColumn label="Статус" value="status" />
          </ExcelSheet>
        ) : null}

        {this.state && this.state.meetingsL != null ? (
          <ExcelSheet data={this.state.meetingsL} name="Правни лица">
            <ExcelColumn label="Состанок со" value="meeting_with" />
            <ExcelColumn label="Тип на производ" value="serviceType" />
            <ExcelColumn label="Регион" value="address" />
            <ExcelColumn label="Контакт" value="contact" />
            <ExcelColumn label="Град" value="city" />
            <ExcelColumn label="Дата" value="date" />
            <ExcelColumn label="Телефон" value="phone" />
            <ExcelColumn label="Статус" value="status" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default ExportMeetingsAll;
