import React, { Component } from 'react';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import LegalEntitiesApi from '../api/legalEntities.js';
import Modal from 'react-modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class LegalEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalEntities: null,
      modalIsOpen: false,
      currentLegalEntity: '',
      currentLegalEntityId: '',
      currentDirector: '',
      userNameError: '',
      emailError: '',
      totalCount: 10,
      searchText: '',
      currentEmail: '',
      page: 1,
    };
    this.getLegalEntities = this.getLegalEntities.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editLegalEntities = this.editLegalEntities.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  async getLegalEntities(pageNumber) {
    try {
      const result = await LegalEntitiesApi.getAllLegalEntities(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText
      );
      this.setState({ legalEntities: result.legalEntities });
    } catch (error) {
      console.log(error);
      toast.error('Грешка при земањето на правни лица.');
    }
  }

  async editLegalEntities() {
    try {
      if (this.state.currentEmail && this.state.currentDirector) {
        await LegalEntitiesApi.editLegalEntity(
          this.state.currentLegalEntityId,
          this.state.currentEmail,
          this.state.currentDirector
        );
        this.closeModal();
        toast.success('Измените се успешно зачувани!');
        window.location.reload();
        this.setState({
          emailError: '',
          currentEmail: '',
          currentLegalEntityId: '',
          currentDirector: ''
        });
      } else {
        this.setState({ emailError: 'Полето не може да биде празно' });
      }
    } catch (error) {
      console.log(error);
      toast.error('Грешка при изменувањето');
    }
  }

  async componentDidMount() {
    await this.getLegalEntities();
  }
  setIsOpen(value) {
    this.setState({ modalIsOpen: value });
  }
  setCurrentLegalEntity(currentLegalEntityId, currentLegalEntity, currentEmail, currentDirector) {
    this.setState({
      currentLegalEntityId: currentLegalEntityId,
      currentLegalEntity: currentLegalEntity,
      currentEmail: currentEmail,
      currentDirector: currentDirector,
      modalIsOpen: true,
    });
  }

  async handlePageClick(e) {
    await this.getLegalEntities(e.selected + 1);
  }

  openModal() {
    this.setIsOpen(true);
  }
  closeModal() {
    this.setIsOpen(false);
    this.setState({ 
        currentEmail: '',
        currentLegalEntityId: '',
        currentDirector: ''
    });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  openModal() {
    this.setIsOpen(true);
  }
  closeModal() {
    this.setIsOpen(false);
    this.setState({ emailError: '' });
  }

  render() {
    Modal.setAppElement('#root');
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };
    return (
      <Row className='table-section'>
        <ToastContainer />
        <Col lg={12}>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel='Example Modal'
            style={customStyles}
          >
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm='6' className='black'>
                  Измени за {this.state.currentLegalEntity}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm='6' className='black'>
                  Внесете Нов емаил
                </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    type='text'
                    placeholder='Нов емаил'
                    name='currentEmail'
                    onChange={this.handleChange}
                  />
                  {this.state.emailError ? (
                    <span className='error'>{this.state.emailError}</span>
                  ) : null}
                </Col>
                <Col sm='10'>
                  <Form.Control
                    type='text'
                    placeholder='Директор'
                    name='currentDirector'
                    onChange={this.handleChange}
                  />
                  {this.state.emailError ? (
                    <span className='error'>{this.state.emailError}</span>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Button
                  onClick={this.editLegalEntities}
                  className='ml-3 mr-3 btn btn-success'
                >
                  Зачувај
                </Button>
                <Button onClick={this.closeModal} className='btn btn-danger'>
                  Откажи
                </Button>
              </Form.Group>
            </Form>
          </Modal>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type='text'
              placeholder='Пребарај правни лица'
              className='mr-sm-2'
              name='searchText'
              onChange={this.handleChange}
            />
            <Button
              variant='outline-dark mb-3 mt-3'
              onClick={() => this.getLegalEntities()}
            >
              Пребарај
            </Button>
          </Form>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead>
                <tr className='table-head'>
                  <th>Деловна Единица</th>
                  <th>Град</th>
                  <th>Подединица</th>
                  <th>Директор</th>
                  <th>Емаил</th>
                  <th>Aкција</th>
                </tr>
              </thead>
              <tbody>
                {this.state.legalEntities !== null
                  ? this.state.legalEntities.rows.map((i) => {
                      return (
                        <tr key={i._id}>
                          <td>{i.optionType}</td>
                          <td>{i.city}</td>
                          <td>{i.subOptionType}</td>
                          <td>{i.director}</td>
                          <td>{i.emails}</td>
                          <td>
                            <Button
                              onClick={() =>
                                this.setCurrentLegalEntity(i._id, i.optionType, i.currentEmail, i.currentDirector)
                              }
                              className='btn button-standard'
                            >
                              Измени
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Претходна'}
              nextLabel={'Следна >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.legalEntities !== null
                  ? Math.ceil(this.state.legalEntities.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    );
  }
}
