import Axios from "axios";
import Cookies from "js-cookie";

class UploadsApi { 

  async uploadImage(data) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const url =
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/uploads/upload-image`
          : `${process.env.REACT_APP_LOCAL_API_URL}/uploads/upload-image`;
      const res = await Axios.post(url, data, configAPi);
      return res;
    } catch (err) {
      throw err;
    }
  }
}

export default new UploadsApi();
