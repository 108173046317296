import React, { Component } from 'react'
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import Download from '../Download'
import BankReferrals from '../../api/bank.referrals'
import { ToastContainer, toast } from 'react-toastify'

export default class BankReferralsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      referrals: null,
      page: 1,
      totalCount: 10,
      searchText: '',
    }
    this.getBankReferrals = this.getBankReferrals.bind(this)
    this.sendCoupon = this.sendCoupon.bind(this)
  }

  async componentDidMount() {
    await this.getBankReferrals()
  }

  async handlePageClick(e) {
    await this.getBankReferrals(e.selected + 1)
  }
  async getBankReferrals(pageNumber) {
    try {
      const result = await BankReferrals.getAllReferrals(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText,
      )
      this.setState({ referrals: result.referrals })
    } catch (error) {
      toast.error('Грешка при преземањето на клиентите')
    }
  }

  async sendCoupon(viber_id) {
    try {
      var res = await BankReferrals.sendCoupon(viber_id)
      toast.success('Ваучерот беше успешно испратен')
      await this.getBankReferrals()
    } catch (error) {
      toast.error('Грешка при испраќањето на ваучерот')
    }
  }
  
  render() {
    return (
      <Row className="table-section">
        <Col lg={12}>
          <figure className="icon-group">
            {/* <img src={OneTimeIcon} alt='One-Time Check Icon' /> */}
            <h2 className="heading ml-3">
              Клиенти што ја препорачале Шпаркасе
            </h2>
          </figure>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Клиент</th>
                  <th>Шпаркасе сметка</th>
                  <th>Телефон</th>
                  <th>Код за препорака</th>
                  <th>Прифатени препораки</th>
                  <th>Регистриран на</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.referrals
                  ? this.state.referrals.rows.map((i) => {
                      let date = new Date(i.created).toLocaleString('en-GB')
                      return (
                        <tr key={i.viber_id}>
                          <td>
                            {i.first_name} {i.last_name}
                          </td>
                          <td>{i.bank_account}</td>
                          <td>{i.phone}</td>
                          <td>{i.code}</td>
                          <td>{i.accepted_invitations}</td>
                          <td>{date}</td>
                          <td>
                            {i.accepted_invitations == 5 ||
                            i.accepted_invitations > 5 ? (
                              <Button
                                onClick={() => this.sendCoupon(i.viber_id)}
                                className="btn btn-success ml-4"
                              >
                                Испрати ваучер
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Претходна'}
              nextLabel={'Следна >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.referrals && this.state.referrals.count
                  ? Math.ceil(this.state.referrals.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    )
  }
}
