import React, { Component } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import Select from "react-select";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import reportsApi from "../../api/reports-api";
import { convertToLocalDateFormat } from "../../helpers/date-helpers";
import ViberBotBulkExport from "../../components/Reports/Exports/ViberBotBulkExport";
import DatePicker from "react-date-picker";
import externalCalls from "../../api/externalCalls";

export default class ViberBulkReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagNames: [],
      tag: null,
      numberOfPending: 0,
      numberOfDelivered: 0,
      numberOfSeen: 0,
      numberOfUnSeen: 0,
      numberOfErrors: 0,
      numberOfAllMessages: 0,
      dateFrom: new Date(),
      dateTo: new Date(),
      numberOfUrlClicks: 0,
      numberOfNonUrlClicks: 0,
      urlStats: null,
      buttonShortUrl: "",
      numberOfClicksDesktop: 0,
      numberOfClicksMobile: 0,
      numberOfClicksTablet: 0,
      numberOfExpired: 0,
    };
    this.tagChange = this.tagChange.bind(this);
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.getTags = this.getTags.bind(this);
    this.getUrlStats = this.getUrlStats.bind(this);
  }

  async onDateFromChange(e) {
    this.setState({ dateFrom: e });
  }

  async onDateToChange(e) {
    this.setState({ dateTo: e });
  }
  async componentDidMount() {
    await this.getTags();
  }

  async getTags() {
    var tagnamesResults = await reportsApi.getTagNames(
      this.state.dateFrom,
      this.state.dateTo
    );
    this.setState({ tagNames: tagnamesResults });
  }

  async getUrlStats(shortUrl) {
    var stats = await externalCalls.getUrlClickStats(shortUrl);
    if (stats) {
      this.setState({
        urlStats: stats,
        numberOfUrlClicks: stats.stats.clicks,
        numberOfNonUrlClicks:
          this.state.numberOfAllMessages - stats.stats.clicks,
      });
      var devices = this.state.urlStats.stats.devices.dev;

      try {
        Object.keys(devices).forEach((key) => {
          if (devices[key].tag === "Desktop") {
            this.setState({
              numberOfClicksDesktop: devices[key].clicks,
            });
            console.log("Desktop:", this.state.numberOfClicksDesktop);
          } else if (devices[key].tag === "Mobile") {
            this.setState({
              numberOfClicksMobile: devices[key].clicks,
            });
            console.log("Mobile:", this.state.numberOfClicksMobile);
          } else if (devices[key].tag === "Tablet") {
            this.setState({
              numberOfClicksTablet: devices[key].clicks,
            });
            console.log("Tablet:", this.state.numberOfClicksTablet);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async tagChange(e) {
    var tag = await reportsApi.getTag(e.value);
    if (tag) {
      var messageStatuses = await reportsApi.getMessageStatuses(
        tag.bulk_process_id
      );

      this.setState({
        tag: tag,
        numberOfPending: messageStatuses.filter((x) => x.status === 2).length,
        numberOfDelivered: messageStatuses.filter((x) => x.status === 3).length,
        numberOfSeen: messageStatuses.filter((x) => x.status === 4).length,
        numberOfErrors: messageStatuses.filter((x) => x.status === 5).length,
        numberOfExpired: messageStatuses.filter((x) => x.status === 6).length,
        numberOfAllMessages: messageStatuses.length,
      });
      if (tag.buttonShortUrl) {
        this.getUrlStats(tag.buttonShortUrl);
      }
    }
  }
  render() {
    return (
      <Container>
        <Row className="mb-4">
          <Col>
            <Select
              options={this.state.tagNames}
              isSearchable="true"
              onChange={this.tagChange}
              placeholder="Одберете кампања"
            ></Select>
          </Col>
          <Col>
            <span>Дата Од </span>
            <DatePicker
              value={this.state.dateFrom}
              onChange={this.onDateFromChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <span>Дата До </span>
            <DatePicker
              value={this.state.dateTo}
              onChange={this.onDateToChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <button onClick={this.getTags}>Пребарај</button>
          </Col>
        </Row>
        <hr />
        {this.state.tag ? (
          <Row>
            <Row style={{ width: "100%" }} className="mb-4">
              <Card style={{ width: "100%" }} className="report-summury-card">
                <ListGroup horizontal style={{ width: "100%" }}>
                  <ListGroup.Item style={{ width: "60%" }}>
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.tag ? this.state.tag.tag : null}
                      </p>
                      <p>
                        {" "}
                        {this.state.tag
                          ? convertToLocalDateFormat(this.state.tag.date_sent)
                          : null}
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ width: "20%" }}
                    className="reports-card-right-info"
                  >
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.numberOfAllMessages}
                      </p>
                      <p> Вкупно Испратени</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "20%" }}>
                    <ViberBotBulkExport id={this.state.tag._id} />
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col xs={12} md={4}>
                <FunnelChart
                  data={[
                    { name: "Во очекување", value: this.state.numberOfPending },
                    { name: "Деливирани", value: this.state.numberOfDelivered },
                    { name: "Прочитани", value: this.state.numberOfSeen },
                    { name: "Истечени", value: this.state.numberOfExpired },
                    { name: "Грешки", value: this.state.numberOfErrors },
                  ]}
                  pallette={["#78E2B1", "#34CE85", "#2AB772", "#29A465"]}
                  getRowNameStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                  getRowValueStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                />
              </Col>
              <Col xs={12} md={4}>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfDelivered != 0
                          ? (
                              parseFloat(
                                this.state.numberOfDelivered /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Деливирани: {this.state.numberOfDelivered}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfSeen != 0
                          ? (
                              parseFloat(
                                this.state.numberOfSeen /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Прочитани: {this.state.numberOfSeen}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfExpired != 0
                          ? (
                              parseFloat(
                                this.state.numberOfExpired /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Истечени: {this.state.numberOfExpired}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfErrors != 0
                          ? (
                              parseFloat(
                                this.state.numberOfErrors /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Неиспратени: {this.state.numberOfErrors}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfPending != 0
                          ? (
                              parseFloat(
                                this.state.numberOfPending /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Во очекување: {this.state.numberOfPending}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                {this.state.tag ? (
                  <Row>
                    <Card style={{ backgroundColor: "#EFEFEF" }}>
                      <Card.Body>
                        <div className="mb-4">
                          <span className="bold-text">Текст</span> :{" "}
                          {this.state.tag.messageText}{" "}
                        </div>
                        <div className="mb-4">
                          <span className="bold-text">Испратена Слика</span> :{" "}
                          {this.state.tag.imageUrl ? "Да" : "Не"}
                        </div>
                        {this.state.tag.imageUrl ? (
                          <div className="mb-4">
                            <span className="bold-text">Слика</span> :{" "}
                            {this.state.tag.imageUrl}{" "}
                          </div>
                        ) : null}
                        <div className="mb-4">
                          <span className="bold-text">Испратено Копче</span> :{" "}
                          {this.state.tag.buttonCaption ? "Да" : "Не"}
                        </div>
                        {this.state.tag.buttonCaption ? (
                          <div className="mb-4">
                            <span className="bold-text">Текст копче</span> :{" "}
                            {this.state.tag.buttonCaption}{" "}
                          </div>
                        ) : null}
                      </Card.Body>
                    </Card>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Row>
        ) : null}
        {/* OD TUKA E ZA BUTTON URL */}

        {this.state.urlStats ? (
          <Row>
            <Row style={{ width: "100%" }} className="mb-2">
              <Card style={{ width: "100%" }} className="report-summury-card">
                <ListGroup horizontal style={{ width: "100%" }}>
                  <ListGroup.Item style={{ width: "60%" }}>
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.tag
                          ? "Статистика на отворени линкови"
                          : null}
                      </p>
                      <p>
                        {" "}
                        {this.state.tag
                          ? convertToLocalDateFormat(this.state.tag.date_sent)
                          : null}
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ width: "40%" }}
                    className="reports-card-right-info"
                  >
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.numberOfAllMessages}
                      </p>
                      <p> Вкупно Испратени</p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col xs={12} md={4}>
                <FunnelChart
                  data={[
                    {
                      name: "Отвориле линк",
                      value: this.state.numberOfUrlClicks,
                    },
                    {
                      name: "Неотвориле линк",
                      value: this.state.numberOfNonUrlClicks,
                    },
                  ]}
                  pallette={["#78E2B1", "#34CE85", "#2AB772", "#29A465"]}
                  getRowNameStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                  getRowValueStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                />
              </Col>
              <Col xs={12} md={4}>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfUrlClicks != 0
                          ? (
                              parseFloat(
                                this.state.numberOfUrlClicks /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Отвориле линк: {this.state.numberOfUrlClicks}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfNonUrlClicks != 0
                          ? (
                              parseFloat(
                                this.state.numberOfNonUrlClicks /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Неотвориле линк: {this.state.numberOfNonUrlClicks}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                {this.state.tag ? (
                  <Row>
                    <Card style={{ backgroundColor: "#EFEFEF" }}>
                      <Card.Body>
                        <div className="mb-4">
                          <span className="bold-text">Линк копче</span> :{" "}
                          {this.state.urlStats.stats.fullLink}{" "}
                        </div>
                        {/* <div className="mb-4">
                          <span className="bold-text">Испратена Слика</span> :{' '}
                          {this.state.tag.imageUrl ? 'Да' : 'Не'}
                        </div>
                        {this.state.tag.imageUrl ? (
                          <div className="mb-4">
                            <span className="bold-text">Слика</span> :{' '}
                            {this.state.tag.imageUrl}{' '}
                          </div>
                        ) : null}
                        <div className="mb-4">
                          <span className="bold-text">Испратено Копче</span> :{' '}
                          {this.state.tag.buttonCaption ? 'Да' : 'Не'}
                        </div>
                        {this.state.tag.buttonCaption ? (
                          <div className="mb-4">
                            <span className="bold-text">Текст копче</span> :{' '}
                            {this.state.tag.buttonCaption}{' '}
                          </div>
                        ) : null} */}
                      </Card.Body>
                    </Card>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Row style={{ width: "100%" }} className="mb-2">
              <Card style={{ width: "100%" }} className="report-summury-card">
                <ListGroup horizontal style={{ width: "100%" }}>
                  <ListGroup.Item style={{ width: "60%" }}>
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.tag
                          ? "Статистика на отворени линкови според уред"
                          : null}
                      </p>
                      <p>
                        {" "}
                        {this.state.tag
                          ? convertToLocalDateFormat(this.state.tag.date_sent)
                          : null}
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{ width: "40%" }}
                    className="reports-card-right-info"
                  >
                    <div style={{ textAlign: "center" }}>
                      <p className="bold-text">
                        {this.state.numberOfAllMessages}
                      </p>
                      <p> Вкупно Испратени</p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col xs={12} md={4}>
                <FunnelChart
                  data={[
                    {
                      name: "Десктоп",
                      value: this.state.numberOfClicksDesktop,
                    },
                    { name: "Мобилен", value: this.state.numberOfClicksMobile },
                    { name: "Таблет", value: this.state.numberOfClicksTablet },
                  ]}
                  pallette={["#78E2B1", "#34CE85", "#2AB772", "#29A465"]}
                  getRowNameStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                  getRowValueStyle={(row) => {
                    return row.value == 999999
                      ? { color: "#BCE4FA" }
                      : { color: "white" };
                  }}
                />
              </Col>
              <Col xs={12} md={4}>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfClicksDesktop != 0
                          ? (
                              parseFloat(
                                this.state.numberOfClicksDesktop /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Десктоп: {this.state.numberOfClicksDesktop}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfClicksMobile != 0
                          ? (
                              parseFloat(
                                this.state.numberOfClicksMobile /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Мобилен: {this.state.numberOfClicksMobile}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
                <Row>
                  <Card
                    className="reports-card"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ListGroup horizontal>
                      <ListGroup.Item className="reports-card-left-info">
                        {this.state.numberOfClicksTablet != 0
                          ? (
                              parseFloat(
                                this.state.numberOfClicksTablet /
                                  this.state.numberOfAllMessages
                              ) * 100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </ListGroup.Item>
                      <ListGroup.Item className="reports-card-right-info">
                        Таблет: {this.state.numberOfClicksTablet}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                {this.state.tag ? (
                  <Row>
                    <Card style={{ backgroundColor: "#EFEFEF" }}>
                      <Card.Body>
                        <div className="mb-4">
                          <span className="bold-text">Линк копче</span> :{" "}
                          {this.state.urlStats.stats.fullLink}{" "}
                        </div>
                      </Card.Body>
                    </Card>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Row>
        ) : null}
      </Container>
    );
  }
}
