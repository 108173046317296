import React, { Component } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { getMonthName } from "../../utils/dates";

import "react-funnel-pipeline/dist/index.css";
import reportsApi from "../../api/reports-api";

export default class ViberFinalChatBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 2022, // TODO get distinct year from DB
      reports: [],
    };
  }

  async getReports() {
    try {
      const reportsResult = await reportsApi.getChatBotFinalReports(
        this.state.year
      );
      this.setState({ reports: reportsResult });
    } catch (error) {}
  }
  async componentDidMount() {
    await this.getReports();
  }

  render() {
    const options = [{ value: 2022, label: 2022 }];
    return (
      <Container>
        <Row className="mb-4">
          <Col>
            <Select options={options} placeholder="Одберете година"></Select>
          </Col>
        </Row>
        <hr />
        <section className="list-box mt-3">
          <Table responsive="xl" className="mb-5">
            <thead className="table-head">
              <tr>
                <th>Месец</th>
                <th>Нови Корисници</th>
                <th>Состаноци</th>
                <th>Потрошувачки Кредити</th>
                <th>Станбени Кредити</th>
                <th>Електронски Канали</th>
                <th>Сметки и Картички</th>
                <th>Пакети</th>
                <th>Локатор на Експозитури</th>
              </tr>
            </thead>
            <tbody>
              {this.state.reports
                ? this.state.reports.map((i) => {
                    return (
                      <tr key={i.month} className="text-center">
                        <td>{getMonthName(i.month)}</td>
                        <td>{i.no_of_subscribers}</td>
                        <td>{i.no_of_meetings}</td>
                        <td>{i.no_of_consumer_loans}</td>
                        <td>{i.no_of_housing_loans}</td>
                        <td>{i.no_of_electrichal_channels}</td>
                        <td>{i.no_of_bills_and_cards}</td>
                        <td>{i.no_of_packages}</td>
                        <td>{i.no_of_branches_locations}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </section>
      </Container>
    );
  }
}
